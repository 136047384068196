import { parseDate, ParsingOption as ChronoParsingOption } from "chrono-node";

export const TIME_DURATION_PATTERN = /\[?(\d*[.,]?\d+)\s?(minute(?:s)?|min|mn|m|h(?:ou)?r(?:s)?|h|day(?:s)?|d|w(?:ee)?k(?:s)?|w)]?/ig;

/**
 * Takes a duration string and returns the duration in minutes. If the string
 * cannot be parsed return null.
 */
export function parseDuration(value: string): number | undefined {
  const matches = value.matchAll(TIME_DURATION_PATTERN);
  let match = matches.next();
  let duration = 0;

  while (!match.done) {
    const num = Number(match.value[1].replace(/,/g, "."));

    if (typeof num !== "number") return;
    if (typeof num === "number" && num <= 0) return;

    const unit = match.value[2];

    if (unit.startsWith("w")) {
      duration += Math.round(num * 7 * 24 * 60);
    } else if (unit.startsWith("d")) {
      duration += Math.round(num * 24 * 60);
    } else if (unit.startsWith("m")) {
      duration += num
    } else {
      // Assume unit is a hour
      duration += Math.round(num * 60);
    }

    match = matches.next();
  }

  return duration > 0 ? duration : undefined;
}

export const parseTime = (value: string, options?: ChronoParsingOption): Date | undefined => {
  if (!value) return undefined;

  // help lazy input look like something chrono can parse (eg. "1" => "1:00")
  let newValue = /^\d{0,2}$/.test(value.trim()) ? `${value}:00` : value;
  // 1:00a => 1:00 a
  newValue = newValue.replace(/(?![\d]+)([ap]m?)/i, (m) => ` ${m.toLowerCase()}`);
  // 2030 => 8:30 pm (note: limit length to eliminate conflicts with long dates)
  if (newValue.length <= 4) {
    newValue = newValue.replace(/([\d]{1,2}(?!:)[\d]{2})/i, (m) => `${m.slice(0, -2)}:${m.slice(-2)}`);
  }

  return parseDate(newValue, undefined, options);
};